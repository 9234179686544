<template>
  <div class="view pa24">
    <div class="d-flex mb20">
      <div class="bgf7fa pt20 pr20 pl20 pb20 d-flex flex-column mr20 w400 h130">
          <div class="d-flex align-items-center">
            <p class="fwbold fs10">合作伙伴数</p>
          </div>
          <p class="fwbold fs10">{{companyInfo.partnerNum}}人</p>
      </div>
      <div class="bgf7fa pt20 pr20 pl20 d-flex flex-column mr20 w400 h130">
          <p class="fwbold fs10">转介绍客户数</p>
          <p class="fwbold fs10">{{companyInfo.customerNum}}人</p>
          <p class="fs7">已成交客户数：{{companyInfo.customerSuccNum}}人</p>
      </div>
      <div class="bgf7fa pt20 pr20 pl20 d-flex flex-column w400 h130">
          <p class="fwbold fs10">成交总金额</p>
          <p class="fwbold fs10">￥{{companyInfo.shopSell + companyInfo.manualSell}}</p>
          <p class="fs7">推广赏金支出金额：￥{{companyInfo.partnerRate + companyInfo.partnerRateWait}}(包含待结算￥{{companyInfo.partnerRateWait}})</p>
          
      </div>
    </div>
    <div class="d-flex align-items-center">
      企业成员名称：
      <el-input class="mr20 m20 w200"  v-model="keyword" placeholder="成员名称"></el-input>
      <el-button type="primary" @click="queryVisitorsData">搜索</el-button>
      <!-- <el-button type="primary" @click="queryVisitorsData">导出</el-button> -->
    </div>
    
    <commonTable
      :tableData="tableData"
      :loading="loading"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      :currentPage="currentPage"
      :total="total"
    >
      <template v-slot:table>
        <el-table-column prop="name" align="center" label="企业成员" />
        <el-table-column prop="partnerNum" align="center" label="拥有下级合作伙伴数" />
        <el-table-column prop="customerNum" align="center" label="转介绍客户总数"/>
        <el-table-column prop="customerSuccNum" align="center" label="已成交客户数"/>
        <el-table-column prop="manualSell" align="center" label="录入成交金额" />
        <el-table-column prop="shopSell" align="center" label="商城交易金额" />
        <el-table-column prop="partnerRate" align="center" label="推广赏金支出金额"/>
        <el-table-column prop="forwardfNum" align="center" label="操作">
          <template slot-scope="scope">
            <div class="d-flex justify-content-center" >
              <div @click="partnerListDetail(scope.row.empId)" class="cblue hover_pointer mr10">
                详情
              </div>
            </div>
          </template>
        </el-table-column>
        
      </template>
    </commonTable>

  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import { getDataTimeSec } from "@/utils";
import {  
  bossSubordinationBalance
 } from "@/api/financing";
import {
  employeeList,
  companyDetails,
} from "@/api/turnToIntroduce";
export default {
  name: "visitorData",
  components: {
    commonTable,
  },
  data() {
    return {
      tableData: [],
      currentPage: 1, //当前页
      pageSize: 10, //显示条数
      loading: false, //表格加载
      total: 0, //总条数
      staffs: [],
      userId: "",
      sourcesId: "",
      authsId: "",
      phone: "",
      totalSells:0, //收入合计
      totalPrice:0, //累计商品收入
      totalCommission:0 ,//累计佣金
      keyword:'',
      companyInfo:''
    };
  },
  filters: {
    getDataTimeSec(val) {
      return getDataTimeSec(val);
    },
  },
  created() {
    this.queryVisitorsData();
    this.companyDetails()
  },
  methods: {
    async queryVisitorsData() {
      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        name:this.name,
      };
      try {
        this.loading = true;
        const result = await employeeList(data);
        this.loading = false;
        const { total, list } = result?.data?.pageInfo;
        this.tableData = list;
        this.total = total;
      } catch (error) {
        this.tableData = []
        this.loading = false;
        console.log(error);
      }
    },
    companyDetails(){
      let data = {
        ignoer:true
      }
      companyDetails(data)
      .then((res)=>{
        console.log(res)
        if(res && res.data){
          this.companyInfo = res.data
        }
      })
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.queryVisitorsData();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.queryVisitorsData();
    },
    partnerListDetail(id){
      this.$router.push({ path:`/partnerListDetailList?id=${id}` });
    }
  },
};
</script>

<style lang="scss" scoped>
</style>